/*
 * This file is used to initialize Mixpanel for drivers registration.
 * Events will be tracked to drivers app Mixpanel project.
 */
const _Mixpanel = require("mixpanel-browser");
const { mix } = require("polished");

const mixpanelToken = process.env.MIXPANEL_DRIVER_APP_TOKEN;
const railsEnv = process.env.RAILS_ENV;
const debugMode = railsEnv !== "production";
const { mixpanel_driver_info } = window.gon;

if (mixpanelToken) {
  const mixpanelWrapper = {};
  let isInitialized = false;

  const debugModeConsoleLog = (message) => {
    if (debugMode) {
      console.log(message);
    }
  };

  _Mixpanel.init(mixpanelToken, {
    debug: debugMode,
    loaded: () => {
      isInitialized = true;
      debugModeConsoleLog("Mixpanel for drivers initialized");
      if(mixpanel_driver_info?.id) {
        _Mixpanel.identify(mixpanel_driver_info.id);
      }
    },
  });

  /**
   * Tracks a mixpanel event
   * @param {string} eventName
   * @param {object} eventData
   */
  mixpanelWrapper.track = (eventName, eventData) => {
    if (isInitialized) {
      return _Mixpanel.track(eventName, {
        ...eventData,
      });
    }
  };

  window.Mixpanel = mixpanelWrapper;
}
